// Example content for each section
import React from 'react';

const Product: React.FC = () => {
  return (
    <div className="section-content">
      <h1>Product</h1>
      <p>Content for Product</p>
    </div>
  );
};

export default Product;
