// Example content for each section
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Form, Row, InputGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Register: React.FC = () => {
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    mobile: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    a_state: '',
    package: '',
    order: ''
  });
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const submitButton = (e: any) => {
    e.preventDefault();
    console.log(form);
    resetButton()
  }

  const resetButton = () => {
    setForm({
      first_name: '',
      last_name: '',
      mobile: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      a_state: '',
      package: '',
      order: ''
    });
  }
  return (
    <div className="section-content">
      <h1> {t("register.pageTitle")}</h1>
      <form className="container container-sm mt-3 mb-3">
        <motion.div
          initial={{x: -100}}
  animate={{ x: 0 }}
  transition={{ fade: "fadeInLeft", duration: 2 }}
>
        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col col-sm-6">
                <Form.Control type="name" name="first_name" value={form.first_name} onChange={handleChange} placeholder="First Name" className="form-control" />
          </Form.Group>
          </Row>
          </motion.div>
        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col col-sm-6">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="name" name="last_name" value={form.last_name} onChange={handleChange} className="form-control" />
            </Form.Group>
        </Row>
    <Row className="mb-3">
        <Form.Group controlId="formBasicMobile" className="col col-sm-6">
            <Form.Label>Mobile Number</Form.Label>
            <InputGroup>
                <InputGroup.Text id="basic-addon1">+40</InputGroup.Text>
                <Form.Control aria-label="Mobile Number" type="mobile" aria-describedby="basic-addon1" className="form-control" name="mobile" value={form.mobile} onChange={handleChange} />
            </InputGroup>
          </Form.Group>
            </Row>
        <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Email</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="email" name="email" value={form.email} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
    </Row>
    <Row className="mb-3">
        <Form.Group className=" col col-sm-6" controlId="formGridAddress1">
            <Form.Label>Address</Form.Label>
            <Form.Control className="form-control" type="text" name="address1" value={form.address1} onChange={handleChange} />
          </Form.Group>
            </Row>
    <Row className="mb-3">
        <Form.Group controlId="formGridCity" className="col col-sm-6">
            <Form.Label>City</Form.Label>
            <Form.Control className="form-control" type="text" name="city" value={form.city} onChange={handleChange} />
          </Form.Group>
            </Row>
        <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-6">
            <Form.Label>State</Form.Label>
            <Form.Select defaultValue="Choose..." className="form-control" name="a_state" value="{form.a_state}" onChange={handleChange}>
                <option value="Choose...">Choose...</option>
                <option value="Delhi">Delhi</option>
                <option value="Bombay">Bommbay</option>
                <option value="New York">New York</option>
                <option value="Kashmir">Kashmir</option>
            </Form.Select>
        </Form.Group>
    </Row>
    <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <Form.Label>Package</Form.Label>
            <Form.Select defaultValue="Choose..." className="form-control" name="package" value="{form.package}" onChange={handleChange}>
                <option value="Choose...">Choose...</option>
                <option value="100GBPackage">100GB Storage</option>
                <option value="150GBPackage">150GB Storage</option>
                <option value="200GBPackage">200GB Storage</option>
            </Form.Select>
          </Form.Group>
            </Row>
        <Row className="mb-3">
        <Form.Group controlId="formGridlabel" className="col col-sm-6">
            <Form.Label>Order Details</Form.Label>
            <Form.Control as="textarea" rows={3} className="form-control" name="order" value={form.order} onChange={handleChange} />
        </Form.Group>
    </Row>
    <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button type="submit" onClick={submitButton} className="me-4 btn btn-success btn-lg btn-block">Submit</button>
            <button type="reset" onClick={resetButton} className="me-4 btn btn-danger btn-lg btn-block">Cancel</button>
        </Form.Group>
    </Row>
</form>
    </div>
  );
};

export default Register;
