// Example content for each section
import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <div className="section-content">
      <h1>About us</h1>
      <p>Content for About us</p>
    </div>
  );
};

export default AboutUs;
