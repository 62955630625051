// Example content for each section
import React from 'react';

const PricePlans: React.FC = () => {
  return (
    <div className="section-content">
      <h1>Plans</h1>
      <p>Content for Plans</p>
    </div>
  );
};

export default PricePlans;
