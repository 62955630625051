import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, NavLink, Routes } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import Homepage from './pages/Homepage/Homepage';
import AboutUs from './pages/AboutUs/AboutUs';
import Product from './pages/Product/Product';
import PricePlans from './pages/PricePlans/PricePlans';
import Register from './pages/Register/Register';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "./assets/images/logo.png";
import { useTranslation } from "react-i18next";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language: string) => {
      i18n.changeLanguage(language);
  };
  

  return (
    <Router>
      <div className="app-container">
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" className="navbar">
          <Container>
            <Navbar.Brand href="/"><img src={logo} className='app-logo' alt='SnapShare Logo'/></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
                <NavLink to="/" className="nav-link" >
                  {t("navbarItems.home")}
                </NavLink>
                <NavLink to="/about-us" className="nav-link" >
                  {t("navbarItems.about-us")}
                </NavLink>
                <NavLink to="/product" className="nav-link" >
                  {t("navbarItems.product")}
                </NavLink>
                 <NavLink to="/price-plans" className="nav-link" >
                  {t("navbarItems.prices")}
                </NavLink>
                 <NavLink to="/register" className="nav-link" >
                  {t("navbarItems.register")}
                </NavLink>
              </Nav>
              <Nav>
                 <NavLink to="#" className="nav-link"  onClick={() => changeLanguage("ro")}>
                  RO
                </NavLink>
                <NavLink to="#" className="nav-link" onClick={() => changeLanguage("en")} >
                  EN
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Routes>
          <Route path="/" element={<Homepage/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/product" element={<Product />} />
          <Route path="/price-plans" element={<PricePlans />} />
          <Route path="/register" element={<Register/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
