// Example content for each section
import React from 'react';
import { motion } from "framer-motion";

const Homepage: React.FC = () => {
  return (
    <div className="section-content">
        <motion.div layout
  animate={{ opacity: 0.45 }}
  transition={{
    opacity: { ease: "linear" },
    layout: { duration: 0.3 }
  }}>

      <h1>Homepage</h1>
      </motion.div>
      <p>Content for Homepage</p>
    </div>
  );
};

export default Homepage;
